<template>
  <div
    v-if="paymentLinks.length === 0"
    class="payment-link-list-table__message"
  >
    <bg-icon
      size="sm"
      name="open-folder"
      class="payment-link-list-table__gears-icon"
    />
    <bg-text
      type="h6"
      color="grey-dark"
      weight="bold"
      class="payment-link-list-table__empty-title"
    >
      Você não possui Links de Pagamento
    </bg-text>
    <bg-text
      type="body-md"
      color="grey-semi-dark"
    >
      Crie um novo link e acompanhe por aqui.
    </bg-text>
    <bg-button @click="router.push({ name: 'PaymentLinkPage' })">
      Criar Link de Pagamento
    </bg-button>
  </div>
  <tr-table
    v-else
    disable-click
  >
    <tr-thead>
      <tr-tr>
        <tr-th width="17%">
          Status
        </tr-th>
        <tr-th width="17%">
          Criado em
        </tr-th>
        <tr-th width="16%">
          Nome
        </tr-th>
        <tr-th width="15%">
          Valor
        </tr-th>
        <tr-th width="15%">
          Métodos
        </tr-th>
        <tr-th width="17%">
          Expiração
        </tr-th>
        <tr-th width="30%">
          Descrição
        </tr-th>
        <tr-th width="10%" />
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="payment in paymentLinks"
        :key="payment.id"
        class="payment-link-list-table__row"
      >
        <tr-td>
          <status-badge
            :status="payment.status"
            :expires-at="payment.expires_at"
          />
        </tr-td>
        <tr-td>
          {{ formatDateTime(payment.created_at, formatDateTimeShape) || '---' }}
        </tr-td>
        <tr-td>
          {{ payment.name }}
        </tr-td>
        <tr-td>
          {{ centsToReal(payment.amount) || '---' }}
        </tr-td>
        <tr-td>
          {{ formatedPaymentMethods(payment.payment_methods) }}
        </tr-td>
        <tr-td>
          {{ formatDateTime(payment.expires_at, 'DD/MM/YYYY') || '---' }}
        </tr-td>
        <tr-td>
          {{ payment.description }}
        </tr-td>
        <tr-td>
          <div class="payment-link-list-table__actions-container">
            <button
              v-tooltip.top-center="
                isDeletable(payment) ? 'Excluir link' : 'O link não pode ser excluído pois já foi recebido'
              "
              class="reset-button"
              aria-label="Excluir link"
              :disabled="!isDeletable(payment)"
              @click.stop="onOpenDeleteDialog(payment.id)"
            >
              <bg-icon
                size="sm"
                name="delete"
                :color="isDeletable(payment) ? 'blue-normal' : 'grey-semi-light'"
                class="payment-link-list-table__actions-icon"
              />
            </button>
            <button
              v-tooltip.top-center="'Copiar link'"
              class="reset-button"
              aria-label="Copiar link"
              @click.stop="copyToClipboard(payment.link)"
            >
              <bg-icon
                size="sm"
                name="copy"
                color="blue-normal"
                class="payment-link-list-table__actions-icon"
              />
            </button>
          </div>
        </tr-td>
      </tr-tr>
    </tr-tbody>
  </tr-table>
</template>

<script setup>
import { BgIcon, BgText, BgButton } from '@transfeeradev/bridge';
import { formatDateTime } from '@/commons/formatters/formatDate';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import formatDateTimeShape from '@/apps/payin/receivables/constants/formatDateTimeShape';
import { formatedPaymentMethods } from '@/apps/payin/formatter/paymentMethod';
import { centsToReal } from '@/commons/formatters/currency';
import { useToaster } from '@/commons/composables/useToaster';
import { useRouter } from 'vue-router';
import StatusBadge from '@/apps/payin/paymentLink/components/StatusBadge.vue';

const toaster = useToaster();
const router = useRouter();

defineProps({
  paymentLinks: {
    required: true,
    type: Array
  },
  onOpenDeleteDialog: {
    type: Function
  }
});

const copyToClipboard = valueToCopy => {
  navigator.clipboard.writeText(valueToCopy);
  toaster.success('Link de Pagamento copiado!');
  checkpointResource.create(checkpoint.PAYMENT_LINK_COPIED_ON_PAYMENT_LINK_TABLE);
};

const isDeletable = payment => payment.status !== 'paid';
</script>

<style scoped>
table {
  margin-top: var(--bg-spacing-lg) !important;
}

.payment-link-list-table__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--bg-spacing-lg);
  gap: var(--bg-spacing-sm);
  max-width: 400px;
  margin-inline: auto;

  & p,
  h6 {
    text-align: center;
  }

  & h6 span {
    display: block;
  }
}

.payment-link-list-table__empty-title {
  max-width: 235px;
}

.payment-link-list-table__status-icon {
  margin-left: var(--bg-spacing-2x-sm);
}

.payment-link-list-table__actions-container {
  display: flex;
}

.payment-link-list-table__actions-container button {
  display: flex;
  align-items: center;
  padding: var(--bg-spacing-2x-sm);
}

.payment-link-list-table__actions-container button:nth-last-child(1) {
  margin-left: var(--bg-spacing-2x-sm);
}

.payment-link-list-table__actions-icon:focus {
  outline: none;
}

.payment-link-list-table__gears-icon {
  height: 40px;
  width: 50px;
}
</style>
