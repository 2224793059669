<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="receivable-details-dialog"
    title="Detalhes da cobrança"
    :has-its-own-buttons="true"
  >
    <div
      :key="key"
      class="modal-container center-h-and-v block"
    >
      <tr-loading
        v-if="loading"
        classes="center-h-and-v width-100"
      />
      <div
        v-else
        class="receivable-details-dialog"
      >
        <status-badge
          :status="receivable.status"
          badge-size="md"
          icon-size="sm"
        />
        <bg-text
          v-if="receivable.payer?.name"
          type="body-md"
          color="grey-dark"
          weight="bold"
        >
          {{ receivable.payer.name }}
        </bg-text>
        <bg-text
          v-if="receivable.payer?.tax_id"
          type="body-md"
          color="grey-semi-dark"
          weight="bold"
        >
          {{ obfuscateAndFormatCPF(receivable.payer.tax_id) }}
        </bg-text>
        <bg-text
          type="h4"
          color="green-semi-dark"
          weight="bold"
        >
          {{ centsToReal(receivable.amount) }}
        </bg-text>
        <div class="receivable-details-dialog__button-wrapper">
          <bg-button
            :aria-label="refundButtonStatus(receivable)"
            left-icon="back-arrow"
            size="sm"
            :disabled="!isRefundable"
            @click.stop="() => $emit('refund-click', receivable)"
          >
            Devolver
          </bg-button>
        </div>
        <div
          v-if="refunds.refunds?.length"
          class="receivable-details-dialog__refund"
        >
          <div class="receivable-details__refund-title">
            <bg-icon
              size="sm"
              name="back-arrow"
              color="grey-dark"
            />
            <bg-text
              type="body-sm"
              weight="bold"
              color="grey-dark"
            >
              Devoluções
            </bg-text>
          </div>
          <div
            v-for="(refund, index) in refunds.refunds"
            :key="refund.id"
            class="receivables-details-dialog__refund-item"
          >
            <bg-text
              type="body-sm"
              color="grey-dark"
            >
              {{ centsToReal(refund.amount) }} em {{ formatDate(refund.created_at) }}
            </bg-text>
            <div
              v-if="isErrorVisible(refund, refunds.refunds?.length, index)"
              class="receivables-details-dialog__refund-error"
            >
              <bg-icon
                size="sm"
                name="alert-outline"
                color="red-semi-dark"
              />
              <bg-text
                type="body-sm"
                color="red-semi-dark"
              >
                Ocorreu um erro nessa tentativa de cobrança
              </bg-text>
            </div>
          </div>
        </div>
        <div
          v-if="isCanceled"
          class="receivable-details-dialog__canceled-text"
        >
          <bg-icon
            size="sm"
            name="close"
            color="grey-dark"
          />
          <bg-text
            type="body-sm"
            weight="bold"
            color="grey-dark"
          >
            <!-- TODO: map cancel motivation to print after comman ','-->
            Essa cobrança foi cancelada
          </bg-text>
        </div>
        <info-action-box
          v-if="receivable.rejectionReason?.message"
          theme="bridge"
          type="error"
        >
          <bg-text type="body-sm">
            {{ receivable.rejectionReason.message }}
          </bg-text>
          <bg-text type="label">
            Código: {{ receivable.rejectionReason.code }}
          </bg-text>
        </info-action-box>
        <hr class="receivable-details-dialog__hr">
        <bg-text
          type="body-md"
          color="grey-dark"
          weight="bold"
        >
          Mais detalhes
        </bg-text>
        <receivable-more-details-list
          class="receivables-details-dialog__more-details"
          :fine-type="receivable.details.fine_type"
          :fine-amount="receivable.details.fine_amount"
          :fine-percent="receivable.details.fine_percent"
          :interest-type="receivable.details.interest_type"
          :interest-amount="receivable.details.interest_amount"
          :interest-percent="receivable.details.interest_percent"
          :pix-key="receivable.details.pix_key"
          :payment-method="receivable.payment_type"
          :created-at="receivable.created_at"
          :updated-at="receivable.updated_at"
          :received-at="receivable.received_at"
          :due-date="receivable.details.due_date"
        />
      </div>
    </div>
  </tr-dialog>
</template>

<script setup>
import { computed, handleError, ref } from 'vue';
import StatusBadge from '@/apps/payin/components/StatusBadge.vue';
import { BgButton, BgIcon, BgText } from '@transfeeradev/bridge';
import formatDate from '@/commons/formatters/formatDate';
import { obfuscateAndFormatCPF } from '@/commons/formatters/cpfCnpj';
import { centsToReal } from '@/commons/formatters/currency';
import { receivableStatus } from '@/apps/payin/receivables/constants/receivableStatus';
import { refundButtonStatus } from '@/apps/payin/receivables/constants/refundButtonStatus';
import { getReceivableById, listRefunds } from '@/apps/payin/receivables/services/receivables';
import ReceivableMoreDetailsList from '@/apps/payin/receivables/components/ReceivableMoreDetailsList.vue';
import { useDelayedLoading } from '@/commons/composables/useDelayedLoading';
import InfoActionBox from '@/commons/components/presentational/InfoActionBox.vue';

defineProps({
  onOpenRefundDialog: {
    type: Function
  }
});
defineEmits(['refund-click']);

const key = ref();
const modal = ref();
const loading = ref(true);
const /** @type {PayinWithDetails} */ receivable = ref({});
const /** @type {PaginatedResponse<Refund>} */ refunds = ref([]);

const open = id => {
  const { delayLoading } = useDelayedLoading({ loading });

  delayLoading(Promise.all([fetchReceivableById(id), fetchRefunds(id)]), () => {
    modal.value.open();
  });
};

const close = () => {
  modal.value.close();
};

const isErrorVisible = (refund, refundsLength, index) => refund?.error && index + 1 === refundsLength;

const fetchReceivableById = async id => {
  try {
    const response = await getReceivableById(id);
    receivable.value = response;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar o recebimento');
  }
};

const fetchRefunds = async id => {
  try {
    const response = await listRefunds(id);
    refunds.value = response;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar o recebimento');
  }
};
const isRefundable = computed(() => receivable.value.refundable_amount > 0);
const isCanceled = computed(() => receivable.value.status === receivableStatus.CANCELED);

defineExpose({
  open,
  close
});
</script>

<style lang="postcss" scoped>
.receivable-details-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--bg-spacing-sm);
}

.receivable-details-dialog__button-wrapper {
  display: flex;
  gap: 8px;
}

.receivable-details-dialog__canceled-text {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: var(--bg-spacing-sm);
  padding: var(--bg-spacing-sm);
  background-color: var(--bg-bg-background);
  border-radius: var(--bg-border-radius-md);

  p {
    margin-left: var(--bg-spacing-x-sm);
  }
}

.receivable-details-dialog__refund {
  width: 100%;
  margin: var(--bg-spacing-sm) 0 var(--bg-spacing-sm) 0;
  align-self: flex-start;
  background-color: var(--bg-bg-background);
  border-radius: var(--bg-border-radius-md);
  padding: 8px;
}

.receivables-details-dialog__refund-item {
  & > p {
    margin-left: 24px;
  }
}

.receivable-details-dialog__hr {
  border: 1px solid var(--bg-grey-light);
  opacity: 0.7;
  width: 100%;
  margin: 0;
}

.receivables-details-dialog__more-details {
  min-width: 350px;
  max-width: 400px;
}

.receivable-details__refund-title {
  display: flex;
  align-items: center;
  gap: var(--bg-spacing-x-sm);
}

.receivables-details-dialog__refund-error {
  display: flex;
  align-items: center;
  gap: var(--bg-spacing-2x-sm);
  margin: 0 0 var(--bg-spacing-x-sm) 24px;
}

.flex {
  display: flex;
}

.modal-container {
  min-height: 400px;
}
</style>
