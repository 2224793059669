import { getRejectionReasonByCode } from '@/apps/payin/receivables/services/rejectionReasons';
import { receivableStatus } from '@/apps/payin/receivables/constants/receivableStatus';
import { receivablesResource } from '@/apps/payin/receivables/resources/receivables';
import { reportsResource } from '@/apps/payin/receivables/resources/reports';
import { refundResource } from '@/apps/payin/receivables/resources/refund';
import moment from '@transfeeradev/moment';
import { realToCents } from '@/commons/formatters/currency';

export async function listReceivables({
  page,
  pageSize,
  search,
  statuses = {},
  paymentTypes = {},
  startDate,
  endDate
}) {
  const status__in = Object.entries(statuses)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const payment_type__in = Object.entries(paymentTypes)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const oneMonthDateFilterLimit = moment('2024-01-01');
  const isEndMonthBeforeLimit = moment(endDate).isBefore(oneMonthDateFilterLimit);
  const isStartMonthBeforeLimit = moment(startDate).isBefore(oneMonthDateFilterLimit);

  if (isEndMonthBeforeLimit) {
    return {
      receivables: [],
      metadata: { pagination: { itemsPerPage: 10, totalItems: null } },
      isEndDateOutOfBounds: true
    };
  }

  const response = await receivablesResource.get({
    page,
    page_size: pageSize,
    status__in,
    payment_type__in,
    search,
    created_at__gte: isStartMonthBeforeLimit ? moment('2024-01-01').toISOString() : moment(startDate).toISOString(),
    created_at__lte: moment(endDate).toISOString()
  });
  return {
    receivables: response.items.map(item => {
      const status =
        {
          [receivableStatus.CANCELED_BY_USER]: 'canceled',
          [receivableStatus.CANCELED_BY_PSP]: 'canceled'
        }[item.status] || item.status;
      return {
        ...item,
        status
      };
    }),
    metadata: response.metadata,
    isEndDateOutOfBounds: false
  };
}

export async function getReceivableById(id) {
  const response = await receivablesResource.getById(id);
  const isCanceled =
    response.status === receivableStatus.CANCELED_BY_USER || response.status === receivableStatus.CANCELED_BY_PSP;
  const isRefunded = response.status === receivableStatus.PARTIALLY_REFUNDED;

  return {
    ...response,
    status: isCanceled ? 'canceled' : isRefunded ? 'refunded' : response.status,
    rejectionReason: getRejectionReasonByCode(response.details.rejection_reason)
  };
}

export async function listRefunds(id) {
  const response = await refundResource.getRefundsByPayinId(id);
  return {
    refunds: response.items.map(item => item),
    metadata: response.metadata
  };
}

export async function refund(id, amount) {
  await refundResource.refund(id, realToCents(amount));
}

export async function createReport({ createdAtGte, createdAtLte, statuses, paymentTypes, search }) {
  const status__in = Object.entries(statuses)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const payment_type__in = Object.entries(paymentTypes)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const res = await reportsResource.exportCSV({
    created_at__gte: moment(createdAtGte).toISOString(),
    created_at__lte: moment(createdAtLte).toISOString(),
    status__in,
    payment_type__in,
    search
  });

  return res.id;
}

export function getReportById(id) {
  return reportsResource.getReportById(id);
}
